import { defineStore } from 'pinia';
import { until } from '@vueuse/core';
import { useCurrentOrg } from '@/modules/currentOrg/composables';
import { getModeLocalStorage, setModeLocalStorage } from './mode';
import { assertIsMode, type Mode } from './types';

export const useModeStore = defineStore('mode', () => {
  // null = not set, '' = set but has no mode (new user)
  const currentMode = ref<null | Mode | ''>(null);

  const { organisation } = useCurrentOrg();

  function setMode(mode: Mode) {
    setModeLocalStorage(mode);
    currentMode.value = mode;
  }

  watch(
    organisation,
    (org) => {
      if (!org) return;

      if (org.isFederated) {
        return setMode('federated');
      }

      // If local storage contains value matching supplier or client
      // Dispatch setMode action to update application state in the mode store
      if (getModeLocalStorage() === 'client' && org.isClient) return setMode('client');
      if (getModeLocalStorage() === 'supplier' && org.isSupplier) return setMode('supplier');

      // Otherwise if no value, check organisation type with client
      // taking precedence over supplier
      if (org.isClient) return setMode('client');
      if (org.isSupplier) return setMode('supplier');

      // Else organisation has no type yet and value will remain empty
      return setMode('');
    },
    { immediate: true },
  );

  return { currentMode, setMode };
});

/**
 * Wraps the pinia store to return a destructurable composable.
 */
export function useMode() {
  const modeStore = useModeStore();

  const currentMode = computed(() => modeStore.currentMode);
  const currentClientOrSupplierMode = computed(() => {
    if (currentMode.value === 'client' || currentMode.value === 'supplier') {
      return currentMode.value;
    }
    throw new Error('Mode is not client or supplier');
  });

  function setMode(mode: string) {
    assertIsMode(mode);
    modeStore.setMode(mode);
  }

  async function resolvedMode() {
    if (currentMode.value === null) {
      await until(currentMode).toMatch((c) => typeof c === 'string');
    }
    if (currentMode.value === null) throw new Error('this is impossible');
    return currentMode.value;
  }

  return { currentMode, resolvedMode, setMode, currentClientOrSupplierMode };
}
