import type { RouteRecordRaw, NavigationGuard } from 'vue-router';
import Permission from '@/composables/permissions/permissions';
import useNewOrgStore from '@/modules/createOrg/store';
import { requireFlag } from './guards/flag';

function requireOrganisationStep(step: number): NavigationGuard {
  return (_to, _from, next) => {
    const newOrgStore = useNewOrgStore();
    if (newOrgStore.unlockedStep < step) {
      next({ name: 'suppliers-new' });
    } else {
      next();
    }
  };
}

export default [
  {
    path: 'suppliers/new',
    name: 'suppliers-new',
    component: () => import('@/modules/createOrg/SupplierNew.vue'),
    meta: {
      permissions: [Permission.ClientsSuppliersManage],
    },
    redirect: { name: 'suppliers-new-organisation' },
    children: [
      {
        path: 'org',
        name: 'suppliers-new-organisation',
        component: () => import('@/modules/createOrg/Organisation.vue'),
      },
      {
        path: 'contact',
        name: 'suppliers-new-contact',
        component: () => import('@/modules/createOrg/Contact.vue'),
        beforeEnter: [requireOrganisationStep(2)],
      },
    ],
  },
  {
    path: 'suppliers/:id/public',
    name: 'suppliers-public-profile',
    component: () => import('@/modules/profile/SupplierPublicProfile.vue'),
    props: true,
    meta: {
      permissions: [Permission.ClientsSuppliersView],
    },
  },
  {
    path: 'suppliers/:id',
    name: 'suppliers-profile',
    alias: '/suppliers/:id',
    component: () => import('@/modules/profile/SupplierProfile.vue'),
    props: true,
    redirect: { name: 'suppliers-profile-overview' },
    meta: {
      permissions: [Permission.ClientsSuppliersView],
    },
    children: [
      {
        name: 'suppliers-profile-overview',
        path: 'overview',
        component: () => import('@/modules/profile/components/overview/OverviewTab.vue'),
      },
      {
        name: 'suppliers-profile-activity',
        path: 'activity',
        component: () => import('@/modules/activityFeed/ActivityFeedConnection.vue'),
      },
      {
        name: 'suppliers-profile-assessment',
        path: 'assessment',
        component: () => import('@/modules/profile/components/assessment/AssessmentTab.vue'),
      },
      {
        name: 'suppliers-profile-external-monitoring',
        path: 'external-monitoring',
        beforeEnter: [requireFlag('externalMonitoringPaidClient')],
        component: () => import('@/modules/externalMonitoring/SupplierProfileMonitoring.vue'),
        props: true,
        children: [
          {
            name: 'suppliers-profile-external-monitoring-scans',
            path: 'scans',
            component: () => import('@/modules/externalMonitoring/SupplierProfileScans.vue'),
          },
          {
            name: 'suppliers-profile-external-monitoring-assets',
            path: 'assets',
            beforeEnter: [requireFlag('externalMonitoringCoreWorkflow')],
            component: () => import('@/modules/externalMonitoring/SupplierProfileAssets.vue'),
          },
          {
            path: '',
            redirect: { name: 'suppliers-profile-external-monitoring-scans' },
          },
        ],
      },
      {
        name: 'suppliers-profile-threats',
        path: 'threats/:threatId?',
        component: () => import('@/modules/emergingThreatsCommon/ThreatsProfileTab.vue'),
        props: true,
      },
      {
        name: 'suppliers-profile-risks',
        path: 'risks/:riskNumber?',
        component: () => import('@/modules/risks/RisksProfileTab.vue'),
        props: true,
      },
      {
        name: 'suppliers-profile-evidence',
        path: 'evidence',
        component: () => import('@/modules/profile/components/evidence/EvidenceOverview.vue'),
      },
      {
        name: 'suppliers-profile-discussions',
        path: 'discussions/:discussionID?',
        component: () => import('@/modules/discussions/DiscussionsTabView.vue'),
        props: (route) => ({
          discussionID: route.params.discussionID,
          query: route.query,
        }),
      },
      {
        name: 'suppliers-profile-notes',
        path: 'notes',
        component: () => import('@/modules/clientNotes/ClientNotes.vue'),
        meta: {
          permissions: [Permission.ClientsSuppliersNotesView],
        },
      },
      {
        name: 'suppliers-profile-about',
        path: 'about',
        component: () => import('@/modules/orgInfo/OrgInfo.vue'),
      },
    ],
  },
  {
    path: 'policies',
    children: [
      {
        path: '',
        name: 'policies',
        component: () => import('@/modules/policy/PolicyListPage.vue'),
      },
      {
        path: 'matrix',
        name: 'policy-matrix',
        component: () => import('@/modules/policy/PolicyMatrix.vue'),
      },
      {
        path: ':id',
        name: 'policies-add-edit',
        component: () => import('@/modules/policy/components/policyPage/PolicyPage.vue'),
        props: true,
      },
    ],
  },
] satisfies RouteRecordRaw[];
