import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import type { MutationObserverOptions } from '@tanstack/vue-query';
import type { AxiosError } from 'axios';
import { useStore } from 'vuex';
import { mergeMutationOptions, mergeQueryOptions, type QueryOptions } from '@/modules/query/utils';
import useFeatureFlags from '@/composables/feature-flags';
import type { Domain } from '@/types/models/domain';
import type { Control } from '@/types/models/control';
import type { CombinedFrameworkConfig, FrameworkConfig } from '@/types/models/framework-config';
import api, { type GetFrameworkResponse } from './api';

export const FRAMEWORK_QUERY = '/framework';

export function useFramework(options?: QueryOptions<GetFrameworkResponse>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [FRAMEWORK_QUERY],
      async queryFn() {
        const res = await api.getFramework();
        return res.data;
      },
      staleTime: Infinity,
    }),
  );

  // Update the vuex store for legacy code to use
  const store = useStore();
  watchEffect(() => {
    if (store && query.data.value) store.commit('framework/SET_FRAMEWORK', query.data.value);
  });

  const domains = computed(() => query.data.value?.domains ?? []);

  const sortedDomains = computed(() =>
    domains.value
      .concat()
      .sort((a, b) => (a.letter > b.letter ? 1 : a.letter < b.letter ? -1 : 0))
      .filter((domain) => !domain.deprecated),
  );

  const addOnDomains = computed(() => domains.value.filter((domain) => domain.isAddOn));

  const domainsByID = computed(() =>
    domains.value.reduce<Record<string, Domain>>((obj, domain) => {
      obj[domain.domainID] = domain;
      return obj;
    }, {}),
  );

  const domainsByLetter = computed(() =>
    domains.value.reduce<Record<string, Domain>>((obj, domain) => {
      obj[domain.letter] = domain;
      return obj;
    }, {}),
  );

  const controls = computed(() => query.data.value?.controls ?? []);

  const sortedControls = computed(() =>
    controls.value
      .concat()
      .sort((a, b) => {
        const x = domainsByID.value[a.domainID]!.letter;
        const y = domainsByID.value[b.domainID]!.letter;
        return x > y ? 1 : x < y ? -1 : a.number - b.number;
      })
      .filter((control) => !control.deprecated),
  );

  const sortedControlsDeprecated = computed(() =>
    controls.value
      .concat()
      .sort((a, b) => {
        const x = domainsByID.value[a.domainID]!.letter;
        const y = domainsByID.value[b.domainID]!.letter;
        return x > y ? 1 : x < y ? -1 : a.number - b.number;
      })
      .filter((c) => c.deprecated || domainsByID.value[c.domainID]!.deprecated),
  );

  const controlsByID = computed(() =>
    controls.value.reduce<Record<string, Control>>((obj, control) => {
      obj[control.controlID] = control;
      return obj;
    }, {}),
  );

  // Does not include deprecated controls
  const controlsByDomain = computed(() =>
    domains.value.reduce<Record<string, string[]>>((obj, domain) => {
      obj[domain.domainID] = sortedControls.value
        .filter((control) => control.domainID === domain.domainID && !control.deprecated)
        .map((control) => control.controlID);
      return obj;
    }, {}),
  );

  const controlsByScanTypeID = computed(() => {
    const pairs = query.data.value?.scanTypeControlPairs || [];
    const controlsByScanType: Record<string, Control[]> = {};

    for (const pair of pairs) {
      if (!controlsByScanType[pair.scanTypeID]) {
        controlsByScanType[pair.scanTypeID] = [];
      }
      const control = controlsByID.value[pair.controlID];
      if (control) {
        controlsByScanType[pair.scanTypeID]!.push(control);
      }
    }

    return controlsByScanType;
  });

  const scanTypeIDsByControlID = computed(() => {
    const pairs = query.data.value?.scanTypeControlPairs || [];
    const scanTypesByControl: Record<string, string[]> = {};

    for (const pair of pairs) {
      if (!scanTypesByControl[pair.controlID]) {
        scanTypesByControl[pair.controlID] = [];
      }
      scanTypesByControl[pair.controlID]!.push(pair.scanTypeID);
    }

    return scanTypesByControl;
  });

  const controlsByDomainDeprecated = computed(() =>
    domains.value.reduce<Record<string, string[]>>((obj, domain) => {
      obj[domain.domainID] = sortedControlsDeprecated.value
        .filter((control) => control.domainID === domain.domainID)
        .map((control) => control.controlID);
      return obj;
    }, {}),
  );

  // Does not include deprecated controls
  const dependentControls = computed(() =>
    sortedControls.value.reduce<Record<string, string[]>>((obj, c) => {
      if (!c.parentID) return obj;
      if (Array.isArray(obj[c.parentID])) obj[c.parentID]!.push(c.controlID);
      else obj[c.parentID] = [c.controlID];
      return obj;
    }, {}),
  );

  // Lookup whether a controlID is a parent of dependent controls or not
  const isParentControl = (controlID: string) =>
    Array.isArray(dependentControls.value[controlID]) &&
    dependentControls.value[controlID].length > 0;

  // Lookup whether a controlID is a dependent control or not
  const isChildControl = (controlID: string) => !!controlsByID.value[controlID]?.parentID;

  // Lookup whether a controlID is the last child of its parent
  const isLastChildControl = (controlID: string) => {
    if (!controlsByID.value[controlID]) return undefined;
    const children = dependentControls.value[controlsByID.value[controlID].parentID];
    if (!children) return undefined;
    return children[children.length - 1] === controlID;
  };

  const certificationsList = computed(() => [
    ...new Set(
      controls.value.reduce<string[]>((obj, control) => {
        if (control.certifications) obj.push(...control.certifications);
        return obj;
      }, []),
    ),
  ]);

  // Takes controlID as a param returns new object with domain letter & control
  // { id: controlID, name: 'A. 3', domainName: 'Security' }
  const getControlAndDomainName = (controlID: string) => {
    const control = controlsByID.value[controlID];
    if (!control) return undefined;
    const domain = domainsByID.value[control.domainID]!;
    return {
      id: controlID,
      name: `${domain.letter}.${control.number}`,
      domainLetter: domain.letter,
      domainName: domain.name,
    };
  };

  return {
    ...query,
    domains,
    sortedDomains,
    domainsByID,
    domainsByLetter,
    controls,
    sortedControls,
    sortedControlsDeprecated,
    controlsByID,
    controlsByScanTypeID,
    scanTypeIDsByControlID,
    controlsByDomain,
    controlsByDomainDeprecated,
    dependentControls,
    isParentControl,
    isChildControl,
    isLastChildControl,
    certificationsList,
    getControlAndDomainName,
    addOnDomains,
    levels: computed(() => query.data.value?.levels || []),
  };
}

export const FRAMEWORK_DEFAULT_CONFIG_QUERY = '/framework/config/default';
export function useFrameworkDefaultConfig(options?: QueryOptions<FrameworkConfig>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [FRAMEWORK_DEFAULT_CONFIG_QUERY],
      async queryFn() {
        const res = await api.getFrameworkDefaultConfig();
        return res.data;
      },
    }),
  );

  return {
    ...query,
    addOnDomains: computed(() => query.data.value?.addOnDomains || []),
    level: computed(() => query.data.value?.level),
  };
}

export function useUpdateFrameworkDefaultConfig(
  options?: MutationObserverOptions<FrameworkConfig, AxiosError, FrameworkConfig, null>,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn(config) {
        const res = await api.setFrameworkDefaultConfig(config);
        return res.data;
      },
      onSuccess(_data, variables) {
        queryClient.setQueryData(
          [FRAMEWORK_DEFAULT_CONFIG_QUERY],
          (oldData: FrameworkConfig | undefined) => {
            if (!oldData) return undefined;
            return {
              ...oldData,
              ...variables,
            } satisfies FrameworkConfig;
          },
        );
      },
    }),
  );
}

export const FRAMEWORK_CONFIG_QUERY = '/framework/config/connection';
export function useFrameworkConfig(
  connectionID: MaybeRefOrGetter<string>,
  options?: QueryOptions<FrameworkConfig, readonly [string, string]>,
) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [FRAMEWORK_CONFIG_QUERY, toRef(connectionID)],
      async queryFn({ queryKey }) {
        const res = await api.getFrameworkConfig(queryKey[1]);
        return res.data;
      },
    }),
  );

  const level = computed(() => query.data.value?.level);
  const addOnDomains = computed(() => query.data.value?.addOnDomains || []);

  const { ff } = useFeatureFlags();
  const { sortedControls, sortedDomains, domainsByID } = useFramework();

  // All domain IDs from domains that are required by the framework config.
  const frameworkRequiredDomainIDs = computed(() =>
    sortedDomains.value
      .filter((d) => {
        if (d.isAddOn) {
          return addOnDomains.value.includes(d.domainID);
        }

        if (!ff('frameworkLevels') || !level.value) return true;

        // Hide domains with no required controls
        return sortedControls.value.some((control) => {
          if (control.domainID !== d.domainID) return false;
          if (!level.value) return false;
          return control.levels.includes(level.value);
        });
      })
      .map((d) => d.domainID),
  );

  const frameworkRequiredControlIDs = computed(() =>
    sortedControls.value
      .filter((c) => {
        if (domainsByID.value[c.domainID]!.isAddOn)
          return frameworkRequiredDomainIDs.value.includes(c.domainID);

        if (!ff('frameworkLevels') || !level.value) return true;

        return c.levels.includes(level.value);
      })
      .map((c) => c.controlID),
  );

  return {
    ...query,
    config: query.data,
    level,
    addOnDomains,
    frameworkRequiredDomainIDs,
    frameworkRequiredControlIDs,
  };
}

export function useUpdateFrameworkConfig(
  options?: MutationObserverOptions<
    FrameworkConfig,
    AxiosError,
    { connectionID: string; config: FrameworkConfig },
    null
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(
    mergeMutationOptions(options, {
      async mutationFn({ connectionID, config }) {
        const res = await api.setFrameworkConfig(connectionID, config);
        return res.data;
      },
      onSuccess(_data, variables) {
        queryClient.setQueryData(
          [FRAMEWORK_CONFIG_QUERY, variables.connectionID],
          (oldData: FrameworkConfig | undefined) => {
            if (!oldData) return undefined;
            return {
              ...oldData,
              ...variables.config,
            } satisfies FrameworkConfig;
          },
        );
      },
    }),
  );
}

export const COMBINED_FRAMEWORK_CONFIG_QUERY = '/framework/config/supplier';
export function useCombinedFrameworkConfig(options?: QueryOptions<CombinedFrameworkConfig>) {
  const query = useQuery(
    mergeQueryOptions(options, {
      queryKey: [COMBINED_FRAMEWORK_CONFIG_QUERY],
      async queryFn() {
        const res = await api.getCombinedFrameworkConfig();
        return res.data;
      },
    }),
  );

  const addOnDomains = computed(() => query.data.value?.addOnDomains || []);
  const levels = computed(() => query.data.value?.levels || []);

  const { sortedControls, sortedDomains, domainsByID } = useFramework();

  const { ff } = useFeatureFlags();

  // All domain IDs from domains that are required by the framework config.
  const frameworkRequiredDomainIDs = computed(() =>
    sortedDomains.value
      .filter((d) => {
        if (d.isAddOn) {
          return addOnDomains.value.includes(d.domainID);
        }

        if (!ff('frameworkLevels') || !levels.value.length) return true;

        // Hide domains with no required controls
        return sortedControls.value.some((control) => {
          if (control.domainID !== d.domainID) return false;
          const configLevels = query.data.value?.levels;
          if (!configLevels) return false;
          return control.levels.some((level) => configLevels.includes(level));
        });
      })
      .map((d) => d.domainID),
  );

  const frameworkRequiredControlIDs = computed(() =>
    sortedControls.value
      .filter((c) => {
        if (domainsByID.value[c.domainID]!.isAddOn)
          return frameworkRequiredDomainIDs.value.includes(c.domainID);

        if (!ff('frameworkLevels') || !levels.value.length) return true;

        return c.levels.some((level) => levels.value.includes(level));
      })
      .map((c) => c.controlID),
  );

  // Update the vuex store for legacy code to use :(
  const store = useStore();
  watchEffect(() => {
    if (store) {
      store.commit('framework/SET_REQUIRED', {
        requiredDomainIDs: frameworkRequiredDomainIDs.value,
        requiredControlIDs: frameworkRequiredControlIDs.value,
      });
    }
  });

  return {
    ...query,
    addOnDomains,
    frameworkRequiredDomainIDs,
    frameworkRequiredControlIDs,
  };
}
