import currentUserApi from '@/modules/currentUser/api';
import * as authSSO from '@/modules/authSSO/sso';
import cognitoAuth from '@/legacy/auth/cognito';

export enum Mode {
  Cognito = 'cognito',
  SSO = 'sso',
}

let authMode: Mode;

export function setAuthMode(mode: Mode) {
  authMode = mode;
  window.localStorage.setItem('authMode', mode);
}

export function getAuthMode() {
  // Get authMode from localStorage and assign to local variable authMode
  const storedMode = window.localStorage.getItem('authMode');
  if (storedMode && Object.values(Mode).includes(storedMode as Mode)) {
    authMode = storedMode as Mode;
    return authMode;
  }
  // If authMode in localStorage is not valid, set authMode to cognito as default.
  setAuthMode(Mode.Cognito);
  return undefined;
}

// isAuthenticated is called in router.beforeEach so this should always be kept up to date.
export const isAuthenticatedRef = ref(false);
export const isAuthenticatedByRLRef = ref(false);

export async function isAuthenticated() {
  // If no value has been assigned to authMode
  // Call getAuthMode to assign
  if (!authMode) getAuthMode();

  try {
    if (authMode === Mode.SSO) {
      const ssoAuthed = await authSSO.isAuthenticated();
      isAuthenticatedRef.value = ssoAuthed;
      return ssoAuthed;
    }

    // Cognito is default auth mode and check
    // even no authMode has been found in localStorage
    await cognitoAuth.getCurrentUser();

    isAuthenticatedRef.value = true;
    return true;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (e) {
    isAuthenticatedRef.value = false;
    return false;
  }
}

let hasMFAPreference = false;

// this includes any extra checks RL applies on top of cognito and sso
// authentication, such as MFA preference
export async function isAuthenticatedByRL() {
  try {
    const isAuthed = await isAuthenticated();

    // if mode is sso we don't need to check mfa preference
    if (authMode === Mode.SSO && isAuthed) {
      isAuthenticatedByRLRef.value = true;
      return true;
    }

    if (!isAuthed) {
      isAuthenticatedByRLRef.value = false;
      return false;
    }

    if (!hasMFAPreference) {
      const resp = await currentUserApi.getUserMFAPreference();

      if (!resp.data.mfaPreference) {
        isAuthenticatedByRLRef.value = false;
        return false;
      }

      const userMFAPreferences = await cognitoAuth.fetchMFAPreference();
      if (!userMFAPreferences?.preferred) {
        isAuthenticatedByRLRef.value = false;
        return false;
      }

      hasMFAPreference = true;
    }
    isAuthenticatedByRLRef.value = true;
    return true;
  } catch (e) {
    isAuthenticatedByRLRef.value = false;
    logError(e);
    return false;
  }
}

export async function getToken() {
  // If no value has been assigned to authMode
  // Call getAuthMode to assign
  if (!authMode) getAuthMode();

  if (authMode === Mode.SSO) {
    return authSSO.getToken();
  }

  // Cognito is default auth mode and check
  // even no authMode has been found in localStorage
  // Call cognito to getToken & return
  const cognitoToken = await cognitoAuth.getToken();
  return cognitoToken;
}

// Logout returns the path to redirect the user to
export async function logout(isSSOEnforcedError = false) {
  // If no value has been assigned to authMode
  // Call getAuthMode to assign
  if (!authMode) {
    getAuthMode();
  }

  if (authMode === Mode.SSO) {
    await authSSO.logout(); // Also does cognito logout
    isAuthenticatedRef.value = false;
    return `/sso/login`;
  }

  try {
    await cognitoAuth.logout();
    isAuthenticatedRef.value = false;
  } catch (err) {
    logError(err);
    // Incase of error - rare, they would have to play with localStorage values
    // Instead of clearing all, clear only cognito related keys
    window.localStorage.clear();
  }
  return isSSOEnforcedError ? '/sso/login?error=sso_enforced' : '/auth/login';
}
