import { createStore } from 'vuex';

// Global
import framework from '@/modules/framework/store-deprecated';
import users from '@/store/modules/users';

// Modules

// DO NOT IMPORT STORES FROM THE MODULE'S INDEX AS THIS CAUSES CIRCULAR DEPENDENCIES.
import activityFeed from '@/modules/activityFeed/store';
import profileSharingLinks from '@/modules/profileSharingLinks/store';
import profileSharingPublic from '@/modules/profileSharingPublic/store';
import actionCentre from '@/modules/actionCentre/store';
import uploads from '@/modules/uploads/store';
import emergingThreats from '@/modules/emergingThreatsCommon/store';
import assessment from '@/modules/assessment/store';
import discussions from '@/modules/discussions/store';
import profile from '@/modules/profile/store';
import labels from '@/modules/labels/store';
import search from '@/modules/search/store';
import invite from '@/modules/invite/store';
import answer from '@/modules/answer/store';
import risks from '@/modules/risks/store';
import federatedInvites from '@/modules/federatedInvites/store';
// zeus
import { zeusFederatedOrgsStore as zeusFederatedOrgs } from '@/modules/zeus/federated/store';

import legacy from '@/legacy/store';

const store = createStore({
  modules: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    legacy,
    framework,
    users,
    actionCentre,
    assessment,
    discussions,
    profile,
    labels,
    search,
    invite,
    activityFeed,
    profileSharingLinks,
    profileSharingPublic,
    uploads,
    answer,
    risks,
    emergingThreats,
    zeusFederatedOrgs,
    federatedInvites,
  },
  strict: import.meta.env.MODE !== 'prod',
});

export default store;
