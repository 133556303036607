export default {
  en: {
    placeholder: 'Write a message...',
    filesAttached: 'Attach files | {n} file attached | {n} files attached',
    send: 'Send',
    failed: 'Comment failed to send, please try again',
    inputHelp: 'Visible to Client & Supplier',
    inputHelpExtra: 'Visible to you and {orgName}',
  },
};
