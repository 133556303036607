export default {
  en: {
    pageTitle: 'Zeus',
    tabs: {
      orgs: 'Organisations',
      users: 'Users',
      threats: 'Emerging Threats',
      federated: 'Federated',
      trials: 'Trials',
      inviteReminders: 'Invite Reminders',
    },
    orgList: {
      noSelection: 'No organisation selected',
      filterBy: 'Filter by:',
      sortBy: 'Sort by:',
      supplier: 'Supplier',
      client: 'Client',
      federated: 'Federated',
      deleted: 'Deleted',
      notDeleted: 'Not Deleted',
      name: 'Name',
      dateJoined: 'Creation Date',
    },
    userList: {
      search: 'Search Users',
      noSelection: 'No user selected',
    },
    searchPlaceholder: 'Search',
    orgPage: {
      fetchingFailed: 'Failed to get organisation info. Please contact Engineering team.',
      managed: 'Managed',
      client: 'Client',
      unclaimed: 'Unclaimed',
      supplier: 'Supplier',
      federated: 'Federated',
      subEntities: 'Federated Sub Entities',
      popular: 'Popular',
      joined: 'Joined {date}',
      status: 'Status',
      profiles: 'Profiles',
      statusTooltip: {
        unconfirmed: 'Lead user has not confirmed their email/phone yet.',
        active:
          'Lead has confirmed email/phone. Org users have access to RL, depending on whether the org is suspended or not.',
        unclaimedSupplier: 'Supplier has not claimed their profile yet.',
        deleted: 'Org is permanently deleted. No other orgs can interact with this org anymore.',
      },
      unconfirmedWithActiveUsers: 'This organisation has confirmed users but is unconfirmed',
      confirmOrg: {
        button: 'Confirm Organisation',
        title: 'Confirm {0}?',
        description: 'Confirming an org will allow its users to login and use Risk Ledger.',
        checkbox:
          'I understand that this is NOT REVERSIBLE and have double-checked with others that this is the correct organisation to confirm.',
        confirm: 'Confirm Organisation',
        failed: 'Failed to confirm org.',
      },
      deleteOrg: {
        title: 'Delete {0} from Risk Ledger?',
        button: 'Delete',
        description:
          'Deleting this organisation will permanently delete their data from Risk Ledger. If they had the supplier side enabled, their organisation will fallback to being an unclaimed profile.',
        checkbox:
          'I understand that this is NOT REVERSIBLE and have double-checked with others that this is the correct organisation to delete.',
        confirm: 'Delete PERMANENTLY',
        failed: 'Failed to delete org.',
      },
      deleteUnclaimedOrg: {
        title: 'Delete {0} Unclaimed Profile from Risk Ledger?',
        button: 'Delete Unclaimed Profile',
        description: 'Deleting this unclaimed profile will fully remove it from the network.',
        checkbox:
          'I understand that this is NOT REVERSIBLE and have double-checked with others that this is the correct organisation to delete.',
        confirm: 'Delete PERMANENTLY',
        failed: 'Failed to delete org.',
      },
      isSuspended: {
        status: 'Suspended',
        tooltip: 'Org users are blocked from logging in and using RL.',
        button: 'Unsuspend...',
        title: 'Unsuspend {0}?',
        description: 'Unsuspending an org will allow its users to login and use Risk Ledger again.',
        failed: 'Failed unsuspending org.',
      },
      isNotSuspended: {
        status: 'Not suspended',
        tooltip: 'Org users can login and access RL.',
        button: 'Suspend...',
        title: 'Suspend {0}?',
        description:
          'Suspending an org will block its users from logging in and using Risk Ledger. This block will be in effect until the org is unsuspended.',
        failed: 'Failed suspending org.',
      },
      isHidden: {
        status: 'Hidden',
        tooltip:
          'This org will not be shown to other orgs in search results, quick-connect page, or invite suggestions.',
        button: 'Unhide...',
        title: 'Unhide {0}?',
        description:
          'Unhiding an org will allow other orgs to see them in search results, quick-connect page, and invite suggestions.',
        failed: 'Failed unhiding org.',
      },
      isNotHidden: {
        status: 'Not hidden',
        tooltip:
          'This org is shown to other org in search results, quick-connect page, and invite suggestions.',
        button: 'Hide...',
        title: 'Hide {0}?',
        description:
          'Hiding an org will stop other orgs from seeing them in search results, quick-connect page, and invite suggestions.',
        failed: 'Failed hiding org.',
      },
      isPaid: {
        status: 'Paid',
        tooltip: 'This org is marked as a paying client.',
        button: 'Unmark as paid...',
        title: 'Unmark {0} as paid?',
        description: 'Unmarking a client org as paid indicates it is a non-paying client.',
        failed: 'Failed unmarking org as paid.',
      },
      isNotPaid: {
        status: 'Not paid',
        tooltip: 'This org is marked as a non paying client',
        button: 'Mark as paid...',
        title: 'Mark {0} as paid?',
        description: 'Marking an org as paid indicates it is a paying client.',
        failed: 'Failed marking org as paid.',
      },
      isClientRestricted: {
        status: 'Client Side Restricted',
        tooltip: 'This org is marked as restricted from viewing the client side',
        button: 'Mark as unrestricted...',
        title: 'Mark {orgName} as unrestricted?',
        description: 'Marking an org as unrestricted indicates that they can view the client side.',
        failed: 'Failed marking org as unrestricted.',
      },
      isNotClientRestricted: {
        status: 'Client Side Not Restricted',
        tooltip: 'This org is marked as unrestricted from viewing the client side',
        button: 'Mark as restricted...',
        title: 'Unmark {orgName} as restricted?',
        description:
          'Marking an org as restricted indicates that they can no longer view the client side.',
        failed: 'Failed marking org as restricted.',
      },
      users: 'Users',
      userTooltip: 'Created {0} ({1})',
      user: '{firstName} {lastName} ({role}, {team})',
      userNoTeam: 'no team',
      addUser: 'Add User',
      clients: 'Clients',
      suppliers: 'Suppliers',
      connectionTooltip: 'Connected {0} ({1})',
      assessment: 'Assessment',
      assessmentStatus: 'Assessment Status:',
      reassessmentStatus: 'Reassessment Status:',
      assessmentProgress: 'Assessment Progress:',
      reassessmentProgress: 'Reassessment Progress:',
      nextReassessment: 'Next Reassessment Due At:',
      percentage: '{0}% Complete',
      assessmentComplete: 'Initial Assessment Complete',
      scopingComplete: 'Scoping Questions Complete',
      scopingIncomplete: 'Waiting For Scoping Questions',
      idle: 'Complete',
      reassessment_due: 'Due',
      reassessment_overdue: 'Overdue',
      reassessment_inprogress: 'In progress',
      initial_inprogress: 'Initial assessment in progress',
      csvExport: 'Export Answers as CSV',
      exportError: 'Error exporting assessment',
      assessmentRemindersPause: 'Pause initial assessment and reassessment reminders',
      assessmentRemindersError: 'There was an error toggling the pause.',
      sso: 'SSO',
      ssoStatus: 'SSO Status:',
      ssoSamlStatus: 'SSO SAML Status:',
      ssoOauthStatus: 'SSO Google OAuth Status:',
      ssoTooltip:
        'Single sign-on (SSO) allows users to log in using their own identity provider instead of their RL password.',
      ssoSamlTooltip:
        'Wether the org has setup their SAML SSO configuration & are using SAML as their SSO method.',
      ssoOauthTooltip:
        'Wether the org has enabled their Google OAuth SSO configuration & are using Google OAuth as their SSO method.',
      ssoEnforced: 'SSO Enforced:',
      ssoEnforcedTooltip:
        'Enforcing SSO means users can only login this way and are not allowed to use their RL password (unless their role is admin or higher).',
      jit: 'JIT user provisioning:',
      jitTooltip: `When just-in-time (JIT) user auto-provisioning is enabled, users form the org's identity provider will be able to login to RL and have an account automatically made for them if they don't have one yet.`,
      jitRole: 'JIT default user role:',
      jitRoleTooltip: `The initial role that the auto-provisioned user will have. Their role can still be changed later.`,
      enabled: 'Enabled',
      disabled: 'Disabled',
      moreInfo: 'More Information',
      pendingCRs: 'Pending connection requests',
      pendingInvites: 'Pending invites',
      inTrial: 'In active trial',
      notInTrial: 'Not in trial',
      seeTrial: 'See trial',
      tos: {
        headline: 'Terms of Service',
        agreement: 'agreed to our',
        deletedUser: ' (user was deleted on {date})',
        date: 'on the',
      },
      sortBy: 'Sort by: ',
      sortOptions: {
        name: 'Name',
        createdAt: 'Created Date',
        connectedAt: 'Connected Date',
        role: 'Role',
        team: 'Team',
        connectionType: 'Connection Type',
      },
    },
    export: {
      letter: 'Letter',
      number: 'Number',
      question: 'Question',
      answer: 'Answer',
      notes: 'Notes',
    },
    userPage: {
      userID: 'User ID',
      cognitoID: 'Cognito ID',
      cognitoStatus: 'Cognito Status',
      authenticatorEnabled: 'Authenticator Enabled',
      true: 'True',
      false: 'False',
      orgID: 'Org ID',
      name: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      phone: 'Phone',
      role: 'Role',
      team: 'Team',
      mode: 'Mode Permission',
      status: 'Status',
      ssoSamlEnabled: 'SSO SAML Enabled',
      ssoOauthEnabled: 'SSO Google OAuth Enabled',
      ssoOnly: 'SSO Only',
      unconfirmed: 'Unconfirmed',
      active: 'Active',
      setMFAToSMS: 'Set MFA to SMS',
      resetMFA: 'Reset MFA Preference',
      confirmUser: 'Confirm User and Organisation',
      generateNewPassword: 'Generate New Temporary Password',
      resendTempPassword: 'Send New Temporary Password Email',
      edit: 'Edit User',
      cancel: 'Cancel',
      add: 'Add User',
      generalWarning: 'Be sure you are editing the correct user!',
      resendTempPassWarning:
        'This will send a new email to the user containing a new temporary password.',
      setMFAToSMSWarning: `The user's authenticator will stop being valid and 2FA will change to SMS on the user's registered phone number.`,
      resetMFAWarning: `The user's MFA preference will be reset and they will be forced to set it up again on login.`,
      mfaPreference: 'MFA/2FA Preference',
      mfaNotSet: 'Not set',
      leadChangeWarning: `Setting a new Lead will demote the organisation's current Lead to an Admin.`,
      confirmUserWarning: `We should make every effort to get the users to confirm themselves through normal email confirmation.`,
      confirmUserWarning2: `This will also mark the organisation as active.`,
      sureCheckbox: `I'm sure the user I'm helping is not being impersonated and I've confirmed their identity.`,
      sureTooltip: `Please make sure to confirm the identity of the user first`,
      newPasswordWarning: `This will generate a new temporary password for the user and replace the existing one.`,
      newPasswordWarning2: `It won't send an email, it will be displayed here for you to copy and share with the user.`,
      newPasswordWarning3: `The user will be required to change their password on next login.`,
      resendTempPassDone: `Success! The user should receive a new email.`,
      editDone: `Success! User details updated.`,
      confirmUserDone: `Success! The org and user are now confirmed.`,
      setMFAToSMSDone: `Success! The user's MFA has been changed to SMS`,
      resetMFADone: `Success! The user's MFA preference has been reset`,
      newPasswordPlaceholder: 'The new password will appear here',
      newPasswordDone: 'Success! Copy the password and share it with the user.',
      close: 'Close',
      alreadyExists: 'A user with that email already exists.',
      error: 'An error occurred. Please try again.',
      refresh: 'Refresh',
      sendInvites: 'Send invites as {0}...',
      labels: {
        lead: 'Lead',
        admin: 'Admin',
        edit: 'Edit',
        view: 'View',
        client: 'Client',
        supplier: 'Supplier',
        client_supplier: 'Client & Supplier',
        federated: 'Federated',
      },
      noTeam: 'No Team',
      teamPlaceholder: 'Select team for user...',
      teams: {
        unknown: 'Unknown - will not set any value',
        sales: 'Sales',
        security: 'Security',
        compliance: 'Compliance',
        procurement: 'Procurement',
        legal: 'Legal',
        esg: 'ESG',
        it: 'IT',
        other: 'Other',
      },
      delete: 'Delete',
      deleteUser: 'Delete User...',
      deleteSpecificUser: 'Delete User: {0}',
      deleteWarning: 'Be sure you are deleting the correct user!',
      deleteEffects: 'The user will no longer be able to access RL with their account.',
      userIsLead: `This is a Lead user; they cannot be deleted. Promote another user to be the organisation's Lead before deleting this one.`,
      userIsSelf: `This user is you! Please get someone else to delete you.`,
      deleteCheckbox: `I'm sure the user I'm helping is not being impersonated, I've confirmed their identity, and this is the correct user to delete.`,
      whyDelete: 'Why is this user being deleted?',
      deleteConfirmTooltip: `Please confirm you're deleting the correct user and provide a reason for deleting them.`,
      deleteSucceeded: 'User deleted.',
      deleteFailed: 'Failed to delete user.',
      restrictSupplierAccess: 'Restrict Supplier Access',
    },
    bulkInvites: {
      changeSender: 'Change Sender',
      pageTitle: 'Send invites as {user} from {org}',
      pageDescription:
        'This page enables you to send invites from the sender above to recipients defined in a CSV file or manually adding them. If you have an Excel file then save it as a CSV.',
      pageDescriptionForCreate:
        'This page enables you to create suppliers and send invites from the sender above to recipients defined in a CSV file or manually adding them. If you have an Excel file then save it as a CSV.',
      csvDescription:
        'The CSV file should have no header and each row should be an organisation name and email separated by a comma. An org name with commas must be enclosed in double quotes. For example:',
      csvDescriptionForCreate:
        'The CSV file should have no header and each row should be an organisation name, email, location, sector and website separated by a comma. An org name with commas must be enclosed in double quotes. For example:',
      csvExample: `Apple Inc,steve{'@'}apple.com\n"This, org, name has commas",alice{'@'}supplier.com`,
      csvExampleForCreate: `Apple Inc,steve{'@'}apple.com,USA,Agriculture,http://apple.com\n"This, org, name has commas",alice{'@'}supplier.com,USA,Agriculture,http://alice.com`,
      load: 'Load CSV',
      loadFailed: 'Failed to load CSV. Ensure it follows the format above and try again.',
      invites: 'Invites',
      orgName: 'Organisation Name',
      email: 'Email',
      location: 'Location',
      sector: 'Sector',
      website: 'Website',
      sentTooltip: 'Sent!',
      send: 'Send',
      inviteExists: 'Invite already exists!',
      orgExists: 'Organisation already exists!',
      connectionExists: 'Organisation is already connected to the existing contact!',
      unexpectedError: 'Unexpected error: {0}',
      allSent: 'Sent all invites!',
      allCreated: 'Created all suppliers and sent all invites!',
      invalidInvites: 'One or more invites have an invalid name and/or email.',
      invalidOrgs: 'One or more organisations have an invalid inputs',
      sendingError: 'Failed to send invites; please try again or contact the Dev Team.',
      creatingError:
        'Failed to create suppliers and send invites; please try again or contact the Dev Team.',
      someFailed: 'Some invites have failed to send; review the inputs and try again.',
      primerEmail: {
        desc: 'I confirm a {primerEmail} has been sent to each supplier.',
        link: 'Primer Email',
      },
    },
    inviteRemindersPage: {
      active: 'Active',
      paused: 'Paused',
      toggleSuccess: 'Successfully updated invite reminder pause status',
      toggleError: 'Failed updating invite reminder pause status',
      searchPlaceholder: 'Search...',
      sortSentAtTooltip: 'Sort by sent date',
    },
    federated: {
      orgDataTable: {
        filterAttributes: {
          name: 'Name',
        },
        columns: {
          name: 'Organisation name',
          createdBy: 'Created by',
          action: 'Actions',
        },
        error: {
          title: 'Federated organisations',
          description: 'Failed fetching organisations.',
        },
        emptyList: {
          title: 'Federated organisations',
          description: 'No federated organisations can be found.',
        },
        actionButton: {
          label: 'Manage',
        },
        deletedUser: 'Deleted User',
      },
      createPanel: {
        title: 'Federated Setup',
        subtitle: 'Create Organisation',
        description:
          "To setup a Federated Organisation, enter their organisation details below. Once created you'll be then prompted to add their first user.",
        nameInput: {
          label: 'Name',
          placeholder: 'Enter a name...',
          error: {
            required: 'Please enter a name.',
          },
        },
        websiteInput: {
          label: 'Website',
          placeholder: 'Enter a website...',
          error: {
            required: 'Please enter a website.',
            url: 'Please enter a valid website.',
          },
        },
        countryInput: {
          label: 'Country',
          placeholder: 'Select a country...',
          error: {
            required: 'Please select a country.',
          },
        },
        industryInput: {
          label: 'Industry',
          placeholder: 'Select an industry...',
          error: {
            required: 'Please select an industry.',
          },
        },
        showPanelButton: {
          label: 'Create Organisation',
        },
        createError: 'Failed creating federated organisation. Please try again.',
        closeButton: {
          label: 'Close',
        },
        createButton: {
          label: 'Create',
        },
      },
      addUserPanel: {
        subtitle: 'Add Users',
        description:
          "You've successfully created a Federated Organisation. We'll now need you to add their first user.",
        orgNameLabel: 'Name',
        orgUsersLabel: 'Users',
        closeButton: {
          label: 'Close',
        },
        showAddModalButton: {
          label: 'Add User',
        },
      },
    },
  },
};
