<script setup lang="ts">
import {
  useFloating,
  flip as flipMiddleware,
  shift as shiftMiddleware,
  offset as offsetMiddleware,
  size as sizeMiddleware,
  autoUpdate,
  type FloatingElement,
  type Placement,
} from '@floating-ui/vue';
import { unrefElement, useEventListener, useTimeoutFn } from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { v4 as uuidv4 } from 'uuid';
import { usePopoverGroups } from './popoverStore';

export type PopoverPlacement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end';

const visible = defineModel<boolean>({ default: false });

const props = withDefaults(
  defineProps<{
    offset?: number;
    flip?: boolean;
    shift?: boolean;
    placement?: PopoverPlacement;
    maxHeight?: number;
    padding?: number;
    trapFocus?: boolean;
    closeOnEscape?: boolean;
    closeOnClickOutside?: boolean;
    hover?: boolean;
    group?: string;
    matchReferenceWidth?: boolean;
  }>(),
  {
    offset: 5,
    flip: true,
    shift: true,
    placement: 'bottom',
    maxHeight: undefined,
    padding: 0,
    trapFocus: true,
    closeOnEscape: true,
    closeOnClickOutside: true,
    hover: false,
    group: '',
    matchReferenceWidth: false,
  },
);

defineSlots<{
  default(props: { show(): void; close(): void; toggle(): void; visible: boolean }): any;
  popover(props: {
    show(): void;
    close(): void;
    toggle(): void;
    visible: boolean;
    placement: Placement;
  }): any;
}>();

// const isDark = inject('darkProvided', false);
const withCSSReset = inject('withCssReset', false);
const id = ref(uuidv4());

const reference = useTemplateRef('reference');
const floating = ref<FloatingElement>();

const floatingMiddleware = computed(() => [
  ...(props.flip ? [flipMiddleware({ padding: props.padding })] : []),
  ...(props.shift ? [shiftMiddleware({ padding: props.padding })] : []),
  offsetMiddleware(props.offset),
  sizeMiddleware({
    padding: props.padding,
    apply({ availableHeight, elements, rects }) {
      Object.assign(elements.floating.style, {
        ...(props.maxHeight
          ? { maxHeight: `${Math.min(props.maxHeight!, availableHeight)}px` }
          : {}),
        ...(props.matchReferenceWidth ? { width: `${rects.reference.width}px` } : {}),
      });
    },
  }),
]);

const {
  floatingStyles,
  update,
  placement: computedPlacement,
} = useFloating(reference, floating, {
  placement: toRef(() => props.placement),
  whileElementsMounted: autoUpdate,
  middleware: floatingMiddleware,
});

defineExpose({
  update,
});

// compute the popover group with the group prop being priority over the injected popover group
const injectedPopoverGroup = inject<string | undefined>('popoverGroup', undefined);
const popoverGroup = computed(() => props.group || injectedPopoverGroup || '');

const popoverGroupsStore = usePopoverGroups();
const openPopoverInGroup = computed(() => popoverGroupsStore.popoverGroups[popoverGroup.value]);

const show = () => {
  visible.value = true;
  if (popoverGroup.value) {
    popoverGroupsStore.popoverGroups[popoverGroup.value] = id.value;
  }
};
const close = () => {
  visible.value = false;
};
const toggle = () => {
  visible.value = !visible.value;
  if (popoverGroup.value) {
    popoverGroupsStore.popoverGroups[popoverGroup.value] = id.value;
  }
};

// if the popover is part of a group and another popover in the group is opened, close this one
watch(openPopoverInGroup, (value) => {
  if (popoverGroup.value && value !== id.value && props.closeOnClickOutside) {
    close();
  }
});

// focus trap handles escape and click outside closing as these actions deactivate the focus trap
// and the post deactivate callback is called
const { activate: activateFocusTrap, deactivate: deactivateFocusTrap } = useFocusTrap(floating, {
  allowOutsideClick: true,
  onPostDeactivate: close,
  fallbackFocus: () => floating.value as HTMLElement,
});

watch(
  visible,
  async (value) => {
    if (!props.trapFocus) return;
    if (value) {
      await nextTick();
      activateFocusTrap();
    } else {
      deactivateFocusTrap();
    }
  },
  { immediate: true },
);

// click outside close
// could use the focus trap for this but it considers clicking the reference element as clicking outside
// and so closes but then the reference element opens the floating element again
function onClickOutside(evt: MouseEvent) {
  if (!props.closeOnClickOutside) return;
  const target = evt.target as Node;
  // ignore clicks on or targets inside the reference element
  const referenceElement = unrefElement(reference);
  if (
    referenceElement &&
    (target.isSameNode(referenceElement) || referenceElement.contains(target))
  )
    return;

  // i think this works for one nested popover in another but not for more
  const teleportTargetEl = document.getElementById('teleport-target');
  // if the target is inside the teleport element
  if (teleportTargetEl?.contains(target)) {
    // and the reference element is not inside the teleport element
    if (!teleportTargetEl?.contains(referenceElement ?? null)) {
      return;
    }
  }

  // is target not inside the floating element
  const floatingElement = unrefElement(floating);
  if (visible.value && target && floatingElement && !floatingElement.contains(target)) {
    close();
  }
}
useEventListener(document, 'click', onClickOutside);

// hover timeout to allow mousing over the floating element and keeping it open
const { start: startCloseTimeout, stop: stopCloseTimeout } = useTimeoutFn(close, 250, {
  immediate: false,
});
const { start: startShowTimeout, stop: stopShowTimeout } = useTimeoutFn(show, 50, {
  immediate: false,
});
const defaultMouseEnter = () => {
  stopCloseTimeout();
  startShowTimeout();
};
const defaultMouseLeave = () => {
  startCloseTimeout();
  stopShowTimeout();
};
</script>

<template>
  <div
    ref="reference"
    v-bind="$attrs"
    @click="toggle"
    @mouseenter="hover && defaultMouseEnter()"
    @mouseleave="hover && defaultMouseLeave()"
    @focus="show"
    @blur="close"
  >
    <slot :show="show" :close="close" :toggle="toggle" :visible="visible">
      <button ref="reference"></button>
    </slot>
  </div>
  <Teleport to="#teleport-target">
    <!-- eslint disabled as focus handled differently for keyboard-only users -->
    <!-- eslint-disable-next-line vuejs-accessibility/mouse-events-have-key-events -->
    <div
      v-if="visible"
      ref="floating"
      class="z-modal box-border rounded border bg-white shadow-float"
      :class="{ 'with-css-reset': withCSSReset }"
      :style="floatingStyles"
      tabindex="-1"
      data-testid="popover"
      @mouseenter="hover && defaultMouseEnter()"
      @mouseleave="hover && defaultMouseLeave()"
    >
      <slot
        name="popover"
        :show="show"
        :close="close"
        :toggle="toggle"
        :visible="visible"
        :placement="computedPlacement"
      ></slot>
    </div>
  </Teleport>
</template>
