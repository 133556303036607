<script lang="ts" setup>
import chroma from 'chroma-js';
import type { IconColour } from '@/components/base/_BaseIcon.vue';
import type { MaterialSymbolName } from '@/types/material-symbol-names';

export type LabelSize = 'small' | 'medium' | 'large';
export type LabelColour = 'default' | 'primary' | 'alert' | 'warning' | 'info' | 'purple';

const props = withDefaults(
  defineProps<{
    colour?: LabelColour;
    size?: LabelSize;
    closeable?: boolean;
    filled?: boolean;
    icon?: MaterialSymbolName;
    iconColour?: IconColour;
    hexColour?: string;
  }>(),
  {
    colour: 'default',
    size: 'small',
    filled: true,
    icon: undefined,
    iconColour: undefined,
    hexColour: undefined,
  },
);

const emit = defineEmits<{
  close: [];
}>();

const isDark = inject('darkProvided', false);

const hexBorderColour = computed(() => {
  if (!props.hexColour) return;
  return props.filled ? chroma(props.hexColour).darken(0.5).hex() : props.hexColour;
});
</script>

<template>
  <span
    class="label border-box inline-flex whitespace-nowrap rounded-full border py-1x leading-[125%]"
    :class="[
      colour,
      {
        'px-1.5x text-sm': size === 'small',
        'px-1.5x text-base': size === 'medium',
        'px-2x text-md': size === 'large',
        filled,
        closeable,
        'is-dark': isDark,
      },
    ]"
    :style="{
      backgroundColor: filled ? hexColour : undefined,
      borderColor: hexBorderColour,
      color: filled ? undefined : hexColour,
    }"
  >
    <BaseIcon v-if="icon" :size="size" :name="icon" :colour="iconColour" outlined />
    <span class="flex flex-1 items-center px-1.5x"><slot /></span>
    <button
      v-if="closeable"
      class="unstyled-button ml-1.5x flex items-center opacity-80"
      @click="emit('close')"
    >
      <BaseIcon :size="size" name="close" class="leading-none" data-testid="label-close" />
    </button>
  </span>
</template>

<style scoped>
.label {
  &.default {
    color: theme('textColor.neutral.DEFAULT');
    border-color: theme('borderColor.neutral.DEFAULT');

    &.filled {
      background-color: theme('backgroundColor.neutral.DEFAULT');
    }

    &.closeable:hover {
      background-color: theme('backgroundColor.neutral.hover');
    }

    &.is-dark {
      color: theme('colors.white');
      border-color: theme('colors.neutral.400');

      &.filled,
      &.closeable:hover {
        background-color: transparent;
      }
    }
  }

  &.primary {
    color: theme('textColor.primary.DEFAULT');
    border-color: theme('borderColor.primary.DEFAULT');

    &.filled {
      background-color: theme('backgroundColor.primary.DEFAULT');
    }

    &.closeable:hover {
      background-color: theme('backgroundColor.primary.hover');
    }
  }

  &.alert {
    color: theme('textColor.error');
    border-color: theme('borderColor.error.DEFAULT');

    &.filled {
      background-color: theme('backgroundColor.error.DEFAULT');
    }

    &.closeable:hover {
      background-color: theme('backgroundColor.error.hover');
    }

    /* TODO: add dark styling for this and other colours */
  }

  &.warning {
    color: theme('textColor.warning');
    border-color: theme('borderColor.warning.DEFAULT');

    &.filled {
      background-color: theme('backgroundColor.warning.DEFAULT');
    }

    &.closeable:hover {
      background-color: theme('backgroundColor.warning.hover');
    }
  }

  &.info {
    color: theme('textColor.info');
    border-color: theme('borderColor.info.DEFAULT');

    &.filled {
      background-color: theme('backgroundColor.info.DEFAULT');
    }

    &.closeable:hover {
      background-color: theme('backgroundColor.info.hover');
    }
  }

  &.purple {
    color: theme('textColor.community');
    border-color: theme('borderColor.community.DEFAULT');

    &.filled {
      background-color: theme('backgroundColor.community.DEFAULT');
    }

    &.closeable:hover {
      background-color: theme('backgroundColor.community.hover');
    }
  }
}
</style>
